module.exports = {
  pathPrefix: `/`,
  url: `https://gema.afnizarnur.com`,
  title: `Undangan Pernikahan Gema & Afnizar`,
  titleTemplate: `Undangan Pernikahan Gema & Afnizar`,
  description: `Sabtu, 28 Maret 2020 di Serang, Banten, Gema & Afnizar akan mengadakan pernikahan. Mari bersama merayakan kebahagiaan!`,
  image: `/images/meta-image-default.png`, // Path to the default meta image in `static` folder
  siteLanguage: `id`, // Language tag on <html> element

  // Web App Manifest
  favicon: `src/images/favicon.png`, // Used for manifest favicon generation
  shortName: `Gema & Afnizar`, // shortname for manifest. *Must* be shorter than 12 characters
  themeColor: `#14151a`,
  backgroundColor: `#f8f2eb`,

  // schema.org JSONLD
  headline: `Undangan Pernikahan Gema & Afnizar`,
  author: ``,

  // Google & Twitter
  twitter: `@afnizarnur`, // Twitter username
}
