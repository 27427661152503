module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"./config/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Undangan Pernikahan Gema & Afnizar","short_name":"Gema & Afnizar","description":"Sabtu, 28 Maret 2020 di Serang, Banten, Gema & Afnizar akan mengadakan pernikahan. Mari bersama merayakan kebahagiaan!","start_url":"/?utm_source=a2hs","background_color":"#f8f2eb","theme_color":"#14151a","display":"standalone","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
